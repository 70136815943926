import { remCalc } from "../../utils";
import { Medium, SemiBold } from "../../utils/font";

export const searchBarStyle = {
    main: {
        backgroundColor: "#FFFFFF",
        borderRadius: '40px',
        opacity: 1,
        width: "100%",
        boxShadow: '0px 0px 16px #00000029',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    othermain: {
        backgroundColor: "#FFFFFF",
        borderRadius: '40px',
        opacity: 1,
        width: "100%",
        boxShadow: '0px 0px 16px #00000029',
        padding: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '30px'
    },
    reset: {
        color: 'text.primary',
        position: {sm: "relative"},
        left: {sm: "10px", xl: 0, lg: 0, md: 0},
        fontFamily:SemiBold
    },
    search: {
        marginTop: '12px'
    },
    innerBox: {
        display: 'flex',
        alignItems: "center",
        width: '100%'
    },
    button: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        width: '100%'
    },
    mainToggle: {
        display: 'flex', marginInlineStart: '30px'
    },
    transparentToggle: {
        borderRadius: '12px 12px 0px 0px',
        backdropFilter: 'blur(18px)',
        color: '#fff',
        padding: '20px',
        width: '100px',
        height: '40px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        zIndex: 0,
        textTransform: 'none',
        fontSize:  remCalc(14),
        backgroundColor: "#00000014",
        "&:hover": {
            backgroundColor: "#00000014",
        }
    },
    transparentToggleSelectedLeft: {
        borderRadius: '12px 12px 0px 0px',
        boxShadow: '0px 3px 6px #00000029',
        backgroundColor: 'primary.main',
        color: '#fff',
        padding: '20px',
        width: '100px',
        height: '40px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginInlineStart: '-20px',
        zIndex: 1,
        textTransform: 'none',
        fontSize:  remCalc(14),
        fontFamily:SemiBold,
        "&:hover": {
            backgroundColor: 'primary.main'
        }
    },
    transparentToggleSelectedRight: {
        borderRadius: '12px 12px 0px 0px',
        boxShadow: '0px 3px 6px #00000029',
        backgroundColor: 'primary.main',
        color: '#fff',
        padding: '20px',
        width: '100px',
        height: '40px',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginInlineEnd: '-20px',
        zIndex: 1,
        textTransform: 'none',
        fontSize:  remCalc(14),
        fontFamily:SemiBold,
        "&:hover": {
            backgroundColor: 'primary.main'
        }
    },
    searchBtn: {
        height: "48px",
        backgroundColor: 'text.primary',
        color: "#fff",
        borderRadius: "24px",
        cursor: "pointer"
    },
    searchText: {
        fontFamily: Medium,
        fontSize:  "1rem",
        marginInlineStart: "8px !important"
    }
}