import { Box } from '@mui/material'
import React from 'react'
import { config } from '../../config'

export const NewLoader = ({ minusHeight = "0px" }) => {
    const style = {
        box: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#fff",
            height: `calc(100vh - ${minusHeight})`
        }
    }
    const hostName = window.location.origin
    const amenitiesUrl = config.amenitiesgoto_url
    const publicListingsUrl = config.listings_url
    const realityUrl = config.realitygoto_url
    const loaderConfiguration = {
        [amenitiesUrl]: <>
            <img src='/images/amenitiesGoto.gif' style={{ width: "250px", height: "250px" }} alt='amenitygoto' />
        </>,
        [publicListingsUrl]: <>
            <img src='/images/listing_Goto.gif' alt='Listingsgoto' />
        </>,
        [realityUrl]: <>
            <img src='/images/realtyGoto.gif' style={{ width: "250px", height: "250px" }} alt='realtygoto' />
        </>
    }
    return (
        <>
            <Box sx={style.box}>
                {loaderConfiguration[hostName]}
            </Box>
        </>
    )
}