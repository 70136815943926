import { Medium, ExtraBold, SemiBold } from "../../utils/font";

export const footerStyle = {
    main: {
        height: '182px',
        backgroundColor: "#fff",
        width: '100%',
        paddingLeft: {
            lg: '84px !important',
            sm: '15px'
        },
        paddingRight: {
            lg: '86px !important',
            sm: '15px'
        },
        paddingTop: '20px',
        borderTop: '1px solid #CED3DD',
        mt: 3
    },
    image: {
        height: '50px', width: '210px', objectFit: 'cover', alignItems: "center",
        display: "flex", cursor: "pointer"
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center"
    },
    firstContainer: {
        display: 'flex'
    },
    secondContainerTop: {
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        paddingTop: '12px'
    },
    secondContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
        paddingTop: '22px'
    },
    secondImage: {
        height: '16px', objectFit: 'cover', alignItems: "center",
        display: "flex"
    },
    footImage: {
        width: '120px'
    },
    copyright: {
        color: 'text.tertiary',
        fontSize: '0.75rem !important',
        fontFamily: SemiBold,
        textDecoration: "none"
    },
    terms: {
        fontSize: "0.875rem !important",
        color: 'text.secondary',
        cursor: "pointer"
    },
    terms1: {
        fontSize: '0.875rem',
        color: '#4E5A6B', mt: "8px",
        fontFamily: SemiBold
    },
    terms2: {
        fontSize: "0.875rem",
        color: '#4E5A6B', mt: "18px",
        fontFamily: SemiBold
    },
    termsCont: {
        display: 'flex', gap: 1, alignItems: 'center'
    },
    UpArrow: {
        boxShadow: "0px 3px 6px #00000029",
        alignItems: "center"

    },
    mainBox: {
        m: 2
    },
    logobox: {
        display: "flex",
        justifyContent: "space-between"
    },
    logoImg: {
        alignItems: "center",
        mt: "16px",
        display: "flex",
        justifyContent: "center"
    },
    prop: {
        color: "#DD7E31",
        fontSize: "0.75rem",
        fontFamily: ExtraBold
    },
    goto: {
        color: "#282828",
        fontSize: "0.75rem",
        fontFamily: ExtraBold
    },
    div: {
        mt: 3
    },
    cpy: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    tp: {
        color: "text.primary",
        fontSize: "1rem",
        fontFamily: Medium,
    },
    ft: {
        mt: 3
    },
    bt1: {
        display: "block",
        p: "8px 0px",
        ml: "-14px"
    },
    bt2: {
        ml: "-3px",
        p: "8px 0px",
    },
    bt3: {
        ml: "-3px",
        p: "8px 0px",
    },
    btnBox: {
        marginInlineStart: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    btn: {
        padding: "10px 10px"
    }

}