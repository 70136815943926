import {
  Box,
  Grid,
  Popover,
  ToggleButton,
  Typography,
  Stack,
  Select,
  TextField,
  MenuItem,
  IconButton,
  Hidden,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { TextBox } from "../textbox";
import { DropUp, DropdownIcon, PlusIcon, MinusIcon } from "../../assets";
import { popoverRangeStyle } from "./style";
import { CustomButton } from "../Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { LocalStorageKeys, remCalc } from "../../utils";
import { withTranslation } from "react-i18next";
// import { UseDebounce } from "../customHooks";
import { DialogBox } from "../dialogBox";
import { maxArea, minArea } from "../../utils/common";

const PopoverRanger = ({
  startIcon = "",
  placeholder = "",
  updateState = () => false,
  type = "",
  options,
  fieldStyle = {},
  value = {},
  isSearched = false,
  clear = false,
  item = [],
  data = "",
  t = () => false,
  onSearchChange = () => { },
  response = "",
  area = '',
  NumberInputSize = '',
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const residential = JSON.parse(localStorage.getItem(LocalStorageKeys.residential_home_page))
  const commercial = JSON.parse(localStorage.getItem(LocalStorageKeys.commercial_home_page))
  const commercialDuration = commercial?.selected?.duration;
  const residentialDuration = residential?.selected?.duration;

  const [textValue, setTextValue] = React.useState({
    bedroom: residential?.selected?.bedroom?.bed ? `${residential?.selected?.bedroom?.bed ? residential?.selected?.bedroom?.bed + " Bedroom " : "Any"}` : "" ||
      commercial?.selected?.bedroom?.bed ? `${commercial?.selected?.bedroom?.bed ? commercial?.selected?.bedroom?.bed + " Bedroom " : "Any"}` : "",
    duration: commercialDuration ? `${commercialDuration?.value} ${commercialDuration?.type}` : '' ||
      residentialDuration ? `${residentialDuration?.value} ${residentialDuration?.type}` : "",
    // duration: "",
    size: (commercial?.selected?.size?.minArea && commercial?.selected?.size?.minArea) ? `${commercial?.selected?.size?.minArea} - ${commercial?.selected?.size?.maxArea} ${commercial?.selected?.size?.type}` : "" ||
      (residential?.selected?.size?.minArea && residential?.selected?.size?.minArea) ? `${residential?.selected?.size?.minArea} - ${residential?.selected?.size?.maxArea} ${residential?.selected?.size?.type}` : "",
    category: 'any'
  });

  // const debounce = UseDebounce()
  const handleSearch = (searchText, e) => {
    setAnchorEl(e)
    onSearchChange(searchText)
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const constructTextValue = (type, value, isSearched) => {
    if (type === "bedroom") {
      let bedroomText = value ? `${value + " Bedroom "} ` : "";
      setTextValue({ ...textValue, bedroom: bedroomText });
      (!isSearched) && handleClose()
    } else if (type === "duration") {
      let durationText = value?.duration?.value ? `${value?.duration?.value} ${value?.duration?.type}` : "";
      setTextValue({ ...textValue, duration: durationText });
      (!isSearched) && handleClose()

    } else if (type === "size") {
      let sizeText = value?.size?.minArea ? `${value?.size?.minArea} - ${value?.size?.maxArea} ${value?.size?.type} ` : ""
      setTextValue({ ...textValue, size: sizeText });
      (!isSearched) && handleClose()
    }
    else if (type === "category") {
      let sizeText = `${value?.size?.minArea}`
      setTextValue({ ...textValue, size: sizeText });
      (!isSearched) && handleClose()
    }
  };

  const Container = styled(Stack)({
    padding: 16,
    // boxShadow: "0px 8px 24px #0717411F",
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
  });

  const CustomSelect = styled(Select)(({ theme }) => ({
    minWidth: "78px",
    backgroundColor: "#F2F4F7",
    borderRadius: 8,
    height: "40px ",
    "& .MuiSelect-select": {
      paddingBlock: "4.5px",
      color: theme.palette.text.primary,
      fontSize: remCalc(14),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F2F4F7",
      borderRadius: 8,
      height: "40px ",
      border: 'none'
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F2F4F7",
      },
    },
  }));

  // const NumberInput = ({ value, onChange }) => (
  //   <TextField
  //     value={value}
  //     onChange={onChange}
  //     type={"number"}
  //     sx={{ ...fieldStyle, ...popoverRangeStyle?.NumberInput, }}
  //     onKeyPress={(e) => {
  //       if (["e", "E"]?.includes(e.key)) {
  //         e.preventDefault();
  //       }
  //     }}
  //   />
  // );


  const SmallNumber = ({ value, onChange }) => (
    <TextField
      value={value}
      onChange={(e) => updateState("size", e.target.value)}
      type={"number"}
      sx={{ ...popoverRangeStyle?.NumberInput, ...fieldStyle }}
      onKeyPress={(e) => {
        if (["e", "E"]?.includes(e.key)) {
          e.preventDefault();
        }
      }}
    />
  );

  React.useEffect(() => {
    if (isSearched) {
      constructTextValue(type, value, isSearched)
    }
    if (clear) {
      setTextValue({
        bedroom: "",
        duration: "",
        size: "",
        category: 'any'

      })
    }
    //  eslint-disable-next-line
  }, [isSearched, value])

  // Toggle Between Popovers
  const selectPopover = (type) => {
    switch (type) {
      case "bedroom":
        return (
          <>
            <Typography sx={popoverRangeStyle.toggleHeader}>{t("Choose Bedroom")}</Typography>
            <Grid container sx={popoverRangeStyle.gap}>
              {options?.bedroom?.map((val) => (
                <ToggleButton
                  value={value?.bedroom?.bed}
                  sx={value?.bedroom?.bed === val ? popoverRangeStyle.toggleSelected : popoverRangeStyle.toggle}
                  onChange={() => updateState("bedroom", "bed", val)}
                  onClick={() => {
                    constructTextValue("bedroom", val)
                  }}
                >
                  {val === 4 ? '4+' : val}
                </ToggleButton>
              ))}
            </Grid>
            {/* <Typography sx={popoverRangeStyle.toggleHeader}>Bath</Typography>
            <Grid container sx={popoverRangeStyle.gap}>
              {options?.bath?.map((val) => (
                <ToggleButton
                  value={value?.bedroom?.bath}
                  sx={value?.bedroom?.bath === val ? popoverRangeStyle.toggleSelected : popoverRangeStyle.toggle}
                  onChange={() => updateState("bedroom", "bath", val)}
                >
                  {val === 4 ? '4+' : val}
                </ToggleButton>
              ))}
            </Grid> */}
            {/* <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("bedroom", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            /> */}
          </>
        );
      case "duration":
        return (
          <>
            <Typography sx={popoverRangeStyle.toggleHeader}>
              {t("Enter Duration")}
            </Typography>
            <Box sx={popoverRangeStyle.gap}>
              <Box width={"45px"}>
                <TextBox
                  isSearch
                  fieldStyle={popoverRangeStyle.texField}
                  type="number"
                  onChange={(e) =>
                    updateState("duration", "value", e.target.value)
                  }
                  value={value?.duration?.value}
                />
              </Box>
              {options?.map((val) => (
                <ToggleButton
                  value={value?.duration?.type}
                  sx={
                    value?.duration?.type === val
                      ? popoverRangeStyle.duratonToggleSelected
                      : popoverRangeStyle.duratonToggle
                  }
                  onChange={() => updateState("duration", "type", val)}
                >
                  {val}
                </ToggleButton>
              ))}
            </Box>
            <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("duration", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            />
          </>
        );
      case "size":
        return (
          <>
            <Typography sx={popoverRangeStyle.toggleHeader}>
              {t("Choose Size")}
            </Typography>
            <Container direction={"row"} alignItems={"center"} gap={2}>
              {/* <NumberInput
                placeholder={t("Min Area")}
                onChange={(e) => updateState("size", "minArea", e.target.value)}
                value={value?.size?.minArea}
              /> */}

              <Box style={popoverRangeStyle.sizeBox}  >
                <NumberInputSize options={minArea}
                  placeholder={t("Min Area")}
                  onChange={(e) => updateState("size", "minArea", e.target.value)}
                  value={value?.size?.minArea} />

                <Typography fontSize={12} color={"text.secondary'"}>
                  {" "}
                  -{" "}
                </Typography>
                {/* <NumberInput
                placeholder={t("Max Area")}
                onChange={(e) => updateState("size", "maxArea", e.target.value)}
                value={value?.size?.maxArea}
              /> */}
                <NumberInputSize options={maxArea}
                  placeholder={t("Max Area")}
                  onChange={(e) => updateState("size", "maxArea", e.target.value)}
                  value={value?.size?.maxArea} />
              </Box>

              <CustomSelect
                onChange={(e) => updateState("size", "type", e.target.value)}
                value={value?.size?.type}
                MenuProps={{ PaperProps: { sx: { maxHeight: "170px", overflow: "auto" } } }}
                labelId="demo-simple-select-disabled-label"
                id="demo-simple-select-disabled"
                disabled
              >
                <MenuItem value={value?.size?.type} disabled={value?.size?.type}>{value?.size?.type}</MenuItem>
              </CustomSelect>
            </Container>
            <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("size", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            />
          </>
        );
      case "member":
        return (
          <>
            <Box
              sx={popoverRangeStyle.memberBox}
            >
              <Typography sx={popoverRangeStyle.duration}>
                {t("Choose Members")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>{t("Adult")}</Typography>
                  <Typography sx={popoverRangeStyle.subtile}>
                    {t("Age Above 13")}
                  </Typography>
                </Grid>
                <Grid item sx={popoverRangeStyle.member}>
                  <IconButton>
                    <MinusIcon />
                  </IconButton>
                  <SmallNumber placeholder={"00"} value={value?.member?.adult} />
                  <IconButton>
                    <PlusIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography>{t("Children")}</Typography>
                  <Typography sx={popoverRangeStyle.subtile}>
                    {t("Age Below 13")}
                  </Typography>
                </Grid>
                <Grid item sx={popoverRangeStyle.member}>
                  <IconButton>
                    <MinusIcon />
                  </IconButton>
                  <SmallNumber placeholder={"00"} value={value?.member?.child} />
                  <IconButton>
                    <PlusIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <CustomButton
              color="secondary"
              variant="contained"
              label="Apply"
              onClick={() => constructTextValue("member", value)}
              rootStyle={popoverRangeStyle.maxWidth}
            />
          </>
        );
      case "category":
        return (
          <>
            <Typography sx={popoverRangeStyle.toggleHeader}>
              {t("Choose Type")}
            </Typography>
            <Box
              sx={popoverRangeStyle.memberBox}
            >
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  sx={popoverRangeStyle.formlabel}
                >
                  {item?.length > 0 ?
                    <>
                      {item?.map((val) => {
                        return (
                          <FormControlLabel onChange={() => {
                            updateState("category", "category", val)
                            handleClose()
                          }} value={val?.value} control={<Radio checked={data === val?.value} />} label={val?.label}
                            sx={popoverRangeStyle.formlabel}
                          />
                        )
                      })}
                    </>
                    :
                    <Box sx={popoverRangeStyle.noDataFound}>
                      {/* <NoDataImageMob width={"100%"} height={150} /> */}
                      <Typography sx={popoverRangeStyle.noDataFoundText}>{t("No Category Found")}</Typography>
                    </Box>
                  }
                </RadioGroup>
              </FormControl>
            </Box>
          </>
        );

      default:
        break;
    }
  };
  const selectTextbox = (type) => {
    switch (type) {
      case "bedroom":
        return (
          <TextBox
            isSearch
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={textValue.bedroom}
            endAdornment={
              anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "duration":
        return (
          <TextBox
            isSearch
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={textValue.duration}
            endAdornment={
              anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "size":
        return (
          <TextBox
            isSearch
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={textValue.size}
            endAdornment={
              anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "member":
        return (
          <TextBox
            isSearch
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={textValue.member}
            endAdornment={
              anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      case "category":
        return (
          <TextBox
            isSearch
            onChange={(e) =>
              handleSearch(e.target.value, e.currentTarget)}
            placeholder={placeholder}
            fieldStyle={fieldStyle}
            value={value ?? textValue.member}
            endAdornment={
              anchorEl ? (
                <DropUp rootStyle={popoverRangeStyle.mt2} />
              ) : (
                <DropdownIcon rootStyle={popoverRangeStyle.mt2} />
              )
            }
            startAdornment={startIcon}
          />
        );
      default:
        break;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Box sx={popoverRangeStyle.main}>
      <Box sx={popoverRangeStyle.textFieldBox} onClick={handleClick}>
        <Box sx={popoverRangeStyle.textFieldBox1}>{selectTextbox(type)}</Box>
      </Box>


      <>
        <Hidden smDown>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={popoverRangeStyle.dialog}
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
          >
            <Box sx={popoverRangeStyle.toggleContainer}>{selectPopover(type)}</Box>
          </Popover>
        </Hidden>
        <Hidden smUp>
          <DialogBox
            open={anchorEl}
            component={<>
              <Box sx={popoverRangeStyle.memberBoxDialog}>
                {selectPopover(type)}
              </Box>
            </>}
            maxWidth="xs"
            header={t(type.charAt(0).toUpperCase() + type.slice(1))}
            handleClose={() => handleClose()}
          />

        </Hidden>
      </>
    </Box>
  );
};

export default withTranslation("popover")(PopoverRanger);
