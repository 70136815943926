import { SvgIcon } from "@mui/material"
import * as React from "react"

export const DayCalender = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#98A0AC",
      ...rootStyle,
    };
    return(
<SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
    <path
      d="M2.889 0A2.9 2.9 0 0 0 0 2.889v10.222A2.9 2.9 0 0 0 2.889 16h10.222A2.9 2.9 0 0 0 16 13.111V2.889A2.9 2.9 0 0 0 13.111 0Zm0 1.333h10.222a1.546 1.546 0 0 1 1.556 1.556v.667H1.333v-.667a1.546 1.546 0 0 1 1.556-1.556ZM1.333 4.889h13.334v8.222a1.546 1.546 0 0 1-1.556 1.556H2.889a1.546 1.546 0 0 1-1.556-1.556Zm6.389 1.78a.664.664 0 0 0-.155.031l-1.333.444a.667.667 0 0 0 .422 1.266l.456-.153v3.964a.667.667 0 0 0 1.333 0V7.333a.666.666 0 0 0-.722-.664Zm5.056.886a.556.556 0 0 0-.556.556v.778h-.889a2 2 0 1 0 0 4h1.444a.556.556 0 0 0 .556-.556V8.111a.556.556 0 0 0-.555-.555Zm-8.788.657a.667.667 0 0 0-.656.676v.444h-.445a.667.667 0 1 0 0 1.333h.444v.444a.667.667 0 1 0 1.333 0v-.444h.444a.667.667 0 1 0 0-1.333h-.444v-.444a.667.667 0 0 0-.677-.676ZM11.333 10h.889v1.778h-.889a.889.889 0 0 1 0-1.778Z"
      fill="currentColor"
    />
  </SvgIcon>
    )
  
}