import { Grid, Box, Button, Stack, Typography, Hidden } from '@mui/material';
import React from "react";
import { CustomButton } from '../Button';
import { searchBarStyle } from "./style";
import { SearchIcon } from '../../assets/components';
import { withTranslation } from 'react-i18next';

const SearchBar = ({
  toggle = '',
  handleToggle = () => false,
  onRedirect = () => false,
  RentSearch,
  BuySearch,
  isToggle = false,
  onReset = () => false,
  t = () => false
}) => {

  return (
    <>
      <Hidden smDown>
        <Box sx={searchBarStyle.mainToggle}>
          {isToggle && <>
            <Button sx={toggle === 'Rent' ? searchBarStyle.transparentToggleSelectedRight : searchBarStyle.transparentToggle} onClick={() => handleToggle('Rent')}>
              {t("Rent")}
            </Button>
            <Button sx={toggle === 'Buy' ? searchBarStyle.transparentToggleSelectedLeft : searchBarStyle.transparentToggle} onClick={() => handleToggle('Buy')}>
              {t("Buy")}
            </Button>
          </>}

        </Box>
        <Grid container sx={isToggle ? searchBarStyle.main : searchBarStyle.othermain}>
          {
            isToggle ?
              <Grid item container xs={9} sm={10} lg={10} md={10} spacing={1}>
                {toggle === 'Rent' ? RentSearch : BuySearch}
              </Grid> :
              <Grid item container xs={9} sm={10} lg={10} md={10} spacing={1}>
                {BuySearch}
              </Grid>
          }


          <Grid item xs={3} sm={2} lg={2} md={2} sx={searchBarStyle.button}>
            <CustomButton label='Reset' rootStyle={searchBarStyle.reset} onClick={onReset} />
            {/* <CustomButton variant='contained' label={t('Search')} color='secondary' startIcon={<SearchIcon rootStyle={searchBarStyle.search} />} onClick={onRedirect} /> */}
            <Stack direction={"row"} spacing={1} sx={searchBarStyle.searchBtn} alignItems={"center"} p={2} onClick={onRedirect}>
              <SearchIcon rootStyle={searchBarStyle.search} />
              <Typography sx={searchBarStyle.searchText}>{t("Search")}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Hidden>
    </>

  )
}
export default withTranslation()(SearchBar)