import { SvgIcon } from "@mui/material"
import * as React from "react"

export const DropdownIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#98A0AC",
      ...rootStyle,
    };
    return(
        <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
        <path
          data-name="icons8-sort-down (1)"
          d="M8.578 0H.422a.422.422 0 0 0-.336.678l4.078 5.344a.422.422 0 0 0 .671 0L8.913.678A.422.422 0 0 0 8.578 0Z"
          fill="currentColor"
        />
      </SvgIcon>
    )
}

