import { SvgIcon } from "@mui/material"
import * as React from "react"

export const BedIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#98A0AC",
      ...rootStyle,
    };

    return(
        <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
        <g data-name="Group 111599">
          <g data-name="Group 96235">
            <path
              data-name="icons8-empty-bed (1)"
              d="M.562 1.999a.571.571 0 0 0-.562.58v11.048a.572.572 0 1 0 1.143 0v-.571h13.714v.571a.572.572 0 1 0 1.143 0v-3.048a1.342 1.342 0 0 0-1.333-1.333H6.1v-.381a2.294 2.294 0 0 0-2.286-2.286H2.286a2.214 2.214 0 0 0-1.143.353V2.579a.571.571 0 0 0-.58-.58Zm1.724 5.722h1.523a1.135 1.135 0 0 1 1.143 1.143v.381H1.143v-.381a1.135 1.135 0 0 1 1.143-1.143Zm-1.143 2.667H5.43a.572.572 0 0 0 .185 0h9.051a.182.182 0 0 1 .19.19v1.333H1.143Z"
              fill="currentColor"
            />
          </g>
        </g>
       </SvgIcon>
    )
 
}
