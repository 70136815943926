import { Medium } from "../../utils/font"

export const DateStyle = {
  text: {
    fontSize: "0.875rem",
    fontFamily: Medium,
    [`& fieldset`]: {
      borderRadius: '24px',
      border: ` 1px solid hsl(0, 0%, 80%) !important`,
      backgroundColor: "#fff",
    },
    width: '100%',
    "& .MuiOutlinedInput-root": {
      "& .MuiTextField-root": {
        width: '100%',
        fontSize: "0.875rem",
        fontFamily: Medium,
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #1F74EA !important",
      },
      "& .MuiInputBase-root-MuiOutlinedInput-root": {
        height: "48px",
        width: "inherit",
        fontSize: "0.875rem",
        fontFamily: Medium,
      },
      "& .MuiInputAdornment-root": {
        zIndex: 1,
        fontSize: "0.875rem",
        fontFamily: Medium,
      },
      "&:hover fieldset": {
        borderColor: "hsl(0, 0%, 80%)",
      },
      "& .MuiOutlinedInput-input.MuiInputBase-input": {
        zIndex: 1,
        padding: '12.5px 6px',
        width: '100%',
        fontSize: "0.875rem",
        fontFamily: Medium,
      },
    },
  },
};
