// Old Font Family Commented As per Request

// export const Regular = "'NotoSans-Regular'";
// export const Bold = "'NotoSans-Bold'";
// export const SemiBold = "'NotoSans-SemiBold'";
// export const ExtraBold = "'NotoSans-ExtraBold'";
// export const Italic = "'NotoSans-Italic'"

export const Regular = "'NotoSans-Regular'";
export const Medium = "'NotoSans-Medium'";
export const SemiBold = "'NotoSans-SemiBold'";
export const ExtraBold = "'NotoSans-ExtraBold'";
export const Italic = "'NotoSans-Italic'";
export const Bold = "'NotoSans-Bold"